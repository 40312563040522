import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useUser } from "../Components/UserContext";
import Creatable from 'react-select/creatable';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminManagementCreate = () => {
    const navigate = useNavigate();
    const { doctorsData } = useUser();
    const [loading, setLoading] = useState(false);
    const [ setError] = useState('');

    const BASE_URL = 'https://live-api.oauthc.gov.ng/v0.1/api/admin';

    const [formData, setFormData] = useState({
        name: '',
        position: '',
        qualification: [], 
        image: [], 
        overviewText: '', 
        mainText: '', 
        email: '',
        phone: '',
        linkdin: '', 
        instagram: '', 
        twitter: '', 
        facebook: '', 
    });

    const [imagePreview, setImagePreview] = useState('');
    const [selectedQualification, setSelectedQualification] = useState([]);

    const qualificationsFromDoctorsData = [...new Set(
        doctorsData.flatMap(doctor =>
            Array.isArray(doctor.qualification) ? doctor.qualification : []
        )
    )].sort((a, b) => a.localeCompare(b));

    const defaultQualificationsOptions = qualificationsFromDoctorsData.map(qualification => ({
        value: qualification,
        label: qualification
    }));
    
    const handleSave = async (e) => {
        e.preventDefault();
        if (!formData.name) {
            setError('Name required');
            return;
        }

        setLoading(true);
        const loadingToastId = toast.loading("Creating management's profile...", {
            autoClose: false,
            toastId: 'loading-toast'
        });

        try {
            const token = localStorage.getItem('bearer_token');
            if (!token) {
                throw new Error('No token found. Please log in.');
            }

            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('position', formData.position);

            selectedQualification.forEach((qualification, index) => {
                formDataToSend.append(`qualification[${index}]`, qualification.value);
            });
            
            // Append the image if a new one is selected
            if (formData.image && formData.image instanceof File) {
                formDataToSend.append('image', formData.image);
            }

            formDataToSend.append('overview_text', formData.overviewText);
            formDataToSend.append('main_text', formData.mainText);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('phone', formData.phone);
            formDataToSend.append('linkdin', formData.linkdin);
            formDataToSend.append('instagram', formData.instagram);
            formDataToSend.append('twitter', formData.twitter);
            formDataToSend.append('facebook', formData.facebook);

            const response = await fetch(`${BASE_URL}/management`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formDataToSend
            });

            const result = await response.json();

            if (response.ok) {
                toast.update(loadingToastId, {
                    render: 'Management profile created successfully!',
                    type: 'success',
                    autoClose: 2000,
                    isLoading: false
                });

                setTimeout(() => {
                    navigate('/admin/management', { replace: true });
                    window.location.reload();
                }, 2500);
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (err) {
            toast.update(loadingToastId, {
                render: `Error: ${err.message}`,
                type: 'error',
                autoClose: 5000,
                isLoading: false
            });
        } finally {
            setLoading(false);
            toast.dismiss('loading-toast');
        }
    };
    
    const handleSelectChange = (newValue, category) => {
        if (category === 'qualification') {
            console.log('Selected Qualifications:', newValue); // Add this log
            setSelectedQualification(newValue);
        } 
    };    

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
    
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'file' ? files[0] : value 
        }));
    };
    
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prevData => ({
                ...prevData,
                image: file
            }));
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        } else {
            setImagePreview('');
        }
    };

    return (
        <>
            <ToastContainer />
            <div>
                <div className="pages-caption">
                    <h1>Pages</h1>
                </div>
                <div className="admin-pages-caption">
                    <h2>Create New Management Profile</h2>
                </div>
            </div>
            <form onSubmit={handleSave} className='details-page-form'>
                <div className="details-inputs">
                    <label>
                        Name: 
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Management Name"
                        />
                    </label>
                    <label>
                        Position: 
                        <input
                            type="text"
                            name="position"
                            value={formData.position}
                            onChange={handleInputChange}
                            placeholder="Management Position"
                        />
                    </label>
                    <label>
                        Qualifications:
                        <Creatable
                            isMulti
                            options={defaultQualificationsOptions}
                            value={selectedQualification}
                            onChange={(options) => handleSelectChange(options, 'qualification')}
                            placeholder="Doctor's Qualifications"
                            className="admin-select"
                            classNames={{
                                control: () => 'react-select__control',
                                option: () => 'react-select__option',
                                menu: () => 'react-select__menu',
                                menuList: () => 'react-select__menu-list',
                                singleValue: () => 'react-select__single-value',
                                placeholder: () => 'react-select__placeholder',
                                dropdownIndicator: () => 'react-select__dropdown-indicator',
                            }}
                        />
                    </label>
                    <label>
                        Profile:
                        <textarea
                            name="overviewText"
                            value={formData.overviewText}
                            onChange={handleInputChange}
                            placholder="Dr. John Doe's profile ..."
                        />
                    </label>
                    <label>
                        Accomplishments:
                        <textarea
                            type="text"
                            name="mainText"
                            value={formData.mainText}
                            onChange={handleInputChange}
                            placeholder="Dr. John Doe's accomplishments ..."
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="john.doe@oauthc.com"
                        />
                    </label>
                    <label>
                        Phone:
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="123 456 7890"
                        />
                    </label>
                    <p>Social links</p>
                    <div className="social-links">
                        <label>
                            Linkdin
                            <input
                                type="text"
                                name="linkdin"
                                value={formData.linkdin}
                                onChange={handleInputChange}
                                placeholder="https://www.linkedin.com/john-doe"
                            />
                        </label>
                        <label>Instagram
                            <input
                                type="text"
                                name="instagram"
                                value={formData.instagram}
                                onChange={handleInputChange}
                                placeholder="https://www.instagram.com/john-doe"
                            />
                        </label>
                        <label>Twitter
                            <input
                                type="text"
                                name="twitter"
                                value={formData.twitter}
                                onChange={handleInputChange}
                                placeholder="https://www.twitter.com/john-doe"
                            />
                        </label>
                        <label>Facebook
                            <input
                                type="text"
                                name="facebook"
                                value={formData.facebook}
                                onChange={handleInputChange}
                                placeholder="https://www.facebook.com/john-doe"
                            />
                        </label>
                    </div>
                </div>
                <div className="details-publish">
                    <div className="image-box">
                        <h4>Image <br /> <span className="image-spec">(237 x 300px)</span> <br />
                        <span className="image-spec">2 Mb Max</span>
                        </h4>
                        <label>
                            <input
                                type="file"
                                name="image"
                                onChange={handleImageChange}
                            />
                            {imagePreview && (
                                <div className="image-preview">
                                    <img src={imagePreview} alt="Preview" />
                                </div>
                            )}
                        </label>
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Creating ...' : 'Create Profile'}
                    </button>
                </div>
            </form>
        </>
    )
};

export default AdminManagementCreate;