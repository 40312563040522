import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useUser } from "../Components/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Creatable from 'react-select/creatable';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';

const AdminManagementUpdate = () => {
    const { id } = useParams();
    const { managementsData, doctorsData } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const BASE_URL = 'https://live-api.oauthc.gov.ng/v0.1/api/admin';

    const [formData, setFormData] = useState({
        name: '',
        position: '',
        qualification: [], 
        image: [], 
        overviewText: '', 
        mainText: '', 
        email: '',
        phone: '',
        linkdin: '', 
        instagram: '', 
        twitter: '', 
        facebook: '', 
    });

    const [imagePreview, setImagePreview] = useState('');
    const [selectedQualification, setSelectedQualification] = useState([]);

    const qualificationsFromDoctorsData = [...new Set(
        doctorsData.flatMap(doctor =>
            Array.isArray(doctor.qualification) ? doctor.qualification : []
        )
    )].sort((a, b) => a.localeCompare(b));

    const defaultQualificationsOptions = qualificationsFromDoctorsData.map(qualification => ({
        value: qualification,
        label: qualification
    }));

    useEffect(() => {
        if (managementsData.length > 0 && id) {
            const managementId = parseInt(id, 10);
            const management = managementsData.find(manage => manage.id === managementId);
    
            if (management) {
                // Set the form data and selected states
                setFormData({
                    name: management.name || '',
                    position: management.position || '',
                    qualification: management.qualification ? management.qualification.map(qual => ({ value: qual, label: qual })) : [],
                    image: management.managementImage || '', // Store the existing image URL as a string
                    overviewText: management.overviewText || '',
                    mainText: management.mainText || '',
                    email: management.email || '',
                    phone: management.phone || '',
                    linkdin: management.linkdin || '',
                    instagram: management.instagram || '',
                    twitter: management.twitter || '',
                    facebook: management.facebook || ''
                });
    
                // Set image preview if available
                if (management.managementImage) {
                    setImagePreview(management.managementImage); // This should show the existing image
                }
    
                // Initialize selected states
                setSelectedQualification(management.qualification ? management.qualification.map(qual => ({ value: qual, label: qual })) : []);
            } else {
                console.log('No management found with the given ID.');
            }
        }
    }, [managementsData, id, doctorsData ]);

    const handleSelectChange = (newValue, category) => {
        if (category === 'qualification') {
            setFormData(prevData => ({
                ...prevData,
                gender: newValue ? newValue.value : '' 
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [category]: newValue 
            }));
        }
    
        if  (category === 'qualification') {
            setSelectedQualification(newValue);
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
    
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'file' ? files : value
        }));
    };
    
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Set the form data with the new file and update the preview
            setFormData(prevData => ({
                ...prevData,
                image: file // Set the new file
            }));
            // Create a preview URL for the new file
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        } else {
            // No file selected, keep the existing image and set preview to existing image
            setImagePreview(formData.image || '');
        }
    };    
    
    const handleSave = async (e) => {
        e.preventDefault();
        if (!formData.name) {
            setError('Name required');
            return;
        }

        setLoading(true);
        const loadingToastId = toast.loading("Updating management's profile...", {
            autoClose: false,
            toastId: 'loading-toast'
        });

        try {
            const token = localStorage.getItem('bearer_token');
            if (!token) {
                throw new Error('No token found. Please log in.');
            }

            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('position', formData.position);

            // Append the qualification names as strings
            selectedQualification.forEach((qualification) => {
                formDataToSend.append('qualification[]', qualification.value); // Append qualification names
            });

            // Append the image if a new one is selected
            if (formData.image && formData.image instanceof File) {
                formDataToSend.append('image', formData.image);
            }

            formDataToSend.append('overview_text', formData.overviewText);
            formDataToSend.append('main_text', formData.mainText);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('phone', formData.phone);
            formDataToSend.append('linkdin', formData.linkdin);
            formDataToSend.append('instagram', formData.instagram);
            formDataToSend.append('twitter', formData.twitter);
            formDataToSend.append('facebook', formData.facebook);

            const response = await fetch(`${BASE_URL}/update/management/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formDataToSend
            });

            const result = await response.json();

            if (response.ok) {
                toast.update(loadingToastId, {
                    render: 'Management profile updated successfully!',
                    type: 'success',
                    autoClose: 2000,
                    isLoading: false
                });

                setTimeout(() => {
                    navigate('/admin/management', { replace: true });
                    window.location.reload();
                }, 2500);
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (err) {
            toast.update(loadingToastId, {
                render: `Error: ${err.message}`,
                type: 'error',
                autoClose: 5000,
                isLoading: false
            });
        } finally {
            setLoading(false);
            toast.dismiss('loading-toast');
        }
    };
    
    if (!managementsData || managementsData.length === 0) {
        return (
            <div className="loading-spinner loading">
                <ClipLoader color="#005046" size={100} />
            </div>
        );
    }

    return (
        <>
            <ToastContainer />
            <div>
                <div className="pages-caption">
                    <h1>Pages</h1>
                </div>
                <div className="back">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <Link to="/admin/management">
                        Back
                    </Link>
                </div>
                <div className="admin-pages-caption">
                    <h2>Update "{formData.name}" Profile</h2>
                </div>
            </div>
            <form onSubmit={handleSave} className='details-page-form'>
                {error && <div className="error">{error}</div>}
                <div className="details-inputs">
                    <label>
                        Name: 
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Dr. John Doe"
                        />
                    </label>
                    <label>
                        Position: 
                        <input
                            type="text"
                            name="position"
                            value={formData.position}
                            onChange={handleInputChange}
                            placeholder="Director"
                        />
                    </label>
                    <label>
                        Qualifications:
                        <Creatable
                            isMulti
                            options={defaultQualificationsOptions}
                            value={selectedQualification}
                            onChange={(options) => handleSelectChange(options, 'qualification')}
                            placeholder="Doctor's Qualifications"
                            className="admin-select"
                            classNames={{
                                control: () => 'react-select__control',
                                option: () => 'react-select__option',
                                menu: () => 'react-select__menu',
                                menuList: () => 'react-select__menu-list',
                                singleValue: () => 'react-select__single-value',
                                placeholder: () => 'react-select__placeholder',
                                dropdownIndicator: () => 'react-select__dropdown-indicator',
                            }}
                        />
                    </label>
                    <label>
                        Profile:
                        <textarea
                            name="overviewText"
                            value={formData.overviewText}
                            onChange={handleInputChange}
                            placholder="Dr. John Doe's profile ..."
                        />
                    </label>
                    <label>
                        Accomplishments:
                        <textarea
                            type="text"
                            name="mainText"
                            value={formData.mainText}
                            onChange={handleInputChange}
                            placeholder="Dr. John Doe's accomplishments ..."
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="john.doe@oauthc.com"
                        />
                    </label>
                    <label>
                        Phone:
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="123 456 7890"
                        />
                    </label>
                    <p>Social links</p>
                    <div className="social-links">
                        <label>
                            Linkdin
                            <input
                                type="text"
                                name="linkdin"
                                value={formData.linkdin}
                                onChange={handleInputChange}
                                placeholder="https://www.linkedin.com/john-doe"
                            />
                        </label>
                        <label>Instagram
                            <input
                                type="text"
                                name="instagram"
                                value={formData.instagram}
                                onChange={handleInputChange}
                                placeholder="https://www.instagram.com/john-doe"
                            />
                        </label>
                        <label>Twitter
                            <input
                                type="text"
                                name="twitter"
                                value={formData.twitter}
                                onChange={handleInputChange}
                                placeholder="https://www.twitter.com/john-doe"
                            />
                        </label>
                        <label>Facebook
                            <input
                                type="text"
                                name="facebook"
                                value={formData.facebook}
                                onChange={handleInputChange}
                                placeholder="https://www.facebook.com/john-doe"
                            />
                        </label>
                    </div>
                </div>
                <div className="details-publish">
                    <div className="image-box">
                        <h4>Image <br /> <span className="image-spec">(237 x 300px)</span> <br />
                        <span className="image-spec">2 Mb Max</span>
                        </h4>
                        <label>
                            <input
                                type="file"
                                name="image"
                                onChange={handleImageChange}
                            />
                            {imagePreview && (
                                <div className="image-preview">
                                    <img src={imagePreview} alt="Preview" />
                                </div>
                            )}
                        </label>
                   </div>
                    
                    <button type="submit" disabled={loading}>
                        {loading ? 'Updating ...' : 'Update Profile'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default AdminManagementUpdate;