import React, { useState, useEffect } from 'react';
import TeamCarousel from './TeamCarousel';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TeamMembersContainer = () => {
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const API_URL = 'https://live-api.oauthc.gov.ng/v0.1/api/home/management';

  useEffect(() => {
    // Function to fetch team members data from the API
    const fetchTeamMembers = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) {
          throw new Error('Failed to fetch team members');
        }
        const data = await response.json();

        // Update the team members data in state
        setTeamMembersData(data.data); // Assuming the API returns an array of team members in `data`
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Something went wrong');
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  if (loading) {
    return <div>Loading team members...</div>;
  }

  if (error) {
    return (
      <div>
        <ToastContainer />
        {toast.error(error, { autoClose: 5000 })}
      </div>
    );
  }

  return (
    <div className="professionals-container">
      <div className="our-professionals-caption">
        <h5>Trusted Health Professionals</h5>
        <h3>Our Professionals</h3>
      </div>
      <TeamCarousel teamMembersData={teamMembersData} />
    </div>
  );
};

export default TeamMembersContainer;